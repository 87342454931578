// Font imports file. If you don't want these fonts, comment out these and add your own into the fonts directory 
// and point the src attribute to the file.
// 

@charset "UTF-8";

@font-face {
  font-family: charter;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url("../fonts/charter_regular.woff2") format('woff2');
}
  
@font-face {
  font-family: charter;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url("../fonts/charter_italic.woff2") format('woff2');
}
  
@font-face {
  font-family: charter;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url("../fonts/charter_bold.woff2") format('woff2');
}
  
@font-face {
  font-family: charter;
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url("../fonts/charter_bold_italic.woff2") format('woff2');
}